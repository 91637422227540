/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

.page-center {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-panel-bkg-color {
  background-color: #f4f3ef;
}

.popover {
  max-width: 600px;
}

.popover-calendar > .popover {
  width: 350px;
  min-width: 350px;
}

.glow-row {
  -webkit-animation: glow linear 1s;
  animation: glow linear 1s;
}

@-webkit-keyframes glow {
  0% {
    background-color: orange;
  }
  100% {
    background-color: inherit;
  }
}
@keyframes glow {
  0% {
    background-color: orange;
  }
  100% {
    background-color: inherit;
  }
}

/* Force Typeahead disabled color */
.rbt-input-multi.form-control[disabled] {
  background-color: #e3e3e3 !important;
  opacity: 1;
}

/* Force Typeahead dropdown input focus behavior */
.rbt-input-multi.form-control.rbt-input.focus {
  height: auto;
  width: auto;
  z-index: 2;
}

.rbt-input-multi.form-control {
  height: auto !important;
  min-height: 43px !important;
}

.rbt-input-main.form-control {
  min-height: 43px !important;
}

.clickable {
  cursor: pointer;
}

.alerts-list-item-updated-utc,
.alerts-header-acknowledged,
.alerts-header-acknowledged-by,
.alerts-header-acknowledged,
.alerts-header-acknowledged-by,
.alerts-header-is-in-maintenance {
  white-space: nowrap;
  text-align: center;
}

.header-showhide-button {
  position: absolute;
  top: 132px;
  left: 278px;
}

.btn-contrast {
  background-color: #51cbce !important;
  color: #ffffff !important;
}
.btn-contrast:focus,
.btn-contrast:active,
.btn-contrast.active,
.btn-contrast:active:focus,
.btn-contrast.active:focus,
.btn-contrast.active:hover,
.show > .btn-contrast.dropdown-toggle,
.show > .btn-contrast.dropdown-toggle:focus {
  background-color: #23648a !important;
  color: #ffffff !important;
  box-shadow: none !important;
}

.btn-contrast:hover,
.btn-contrast:active:hover,
.show > .btn-contrast.dropdown-toggle:hover {
  background-color: #4588af !important;
  color: #ffffff !important;
  box-shadow: none !important;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 2s infinite;
}

.pointer {
  cursor: pointer;
}

.input-like {
  pointer-events: none;
  color: #66615b;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  background-color: #e3e3e3;
  display: inline-block;
  width: 100%;
}
