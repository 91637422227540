.notifications {
  position: absolute;
  z-index: 1100;
  right: $margin-base-horizontal * 2;
  top: $margin-base-vertical * 2;

  .toast-header {
    // border-bottom: none;
    cursor: pointer;
  }
  .toast-body {
    cursor: pointer;
  }
}
