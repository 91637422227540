.dropup .dropdown-toggle:after,
.dropdown .dropdown-toggle:after {
  margin-left: 3px;
}

.rbt-menu > .dropdown-item {
  text-overflow: inherit !important;
}

.dropdown-menu .dropdown-item .rolling-text {
  position: relative;
}

.dropdown-menu .dropdown-item:hover .rolling-text {
  animation: slide 4s infinite;
}

.dropdown-menu .dropdown-item.active .rolling-text {
  animation: slide 4s infinite;
}

@keyframes slide {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
