select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 4px);
}

.form-control {
  height: unset;
}

th {
  select.form-control:not([size]):not([multiple]) {
    height: calc(1.65rem + 4px);
  }
}
