.page-header {
  min-height: 100vh;
  max-height: 1000px;
  padding: 0;
  color: $white-color;
  position: relative;

  .page-header-image {
    position: absolute;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .content-center {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    color: #ffffff;
    padding: 0 15px;
    width: 100%;
    max-width: 880px;
  }

  footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .container {
    height: 100%;
    z-index: 1;
  }

  .category,
  .description {
    color: $opacity-8;
  }

  &.page-header-small {
    min-height: 60vh;
    max-height: 440px;
  }

  &.page-header-mini {
    min-height: 40vh;
    max-height: 340px;
  }

  .title {
    margin-bottom: 15px;
  }
  .title + h4 {
    margin-top: 10px;
  }

  &:after,
  &:before {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: '';
  }

  &:before {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &[filter-color='orange'] {
    @include linear-gradient(rgba($black-color, 0.2), rgba(224, 23, 3, 0.6));
  }
}
